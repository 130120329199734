<!-- Start Navbar Area -->
<header id="header">
    <div class="crake-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/00-logotipo-AISA.svg" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav nav ml-auto">

                        <li><a routerLink="/" routerLinkActive="active">Soluciones</a></li>

                        <li><a routerLink="/" routerLinkActive="active">Nosotros</a></li>

                        <li><a routerLink="/" routerLinkActive="active">Talentos</a></li>
                        
                        <li><a routerLink="/" routerLinkActive="active">Contacto</a></li>

                    </ul>
                </div>

            </nav>
        </div>
    </div>
</header>
<!-- End Navbar Area -->

<app-sidebar></app-sidebar>

<!-- Start Search Box -->
<!-- <div id="header-search" class="header-search">
    <button type="button" class="close">×</button>
    <form class="header-search-form">
        <input type="search" value="" placeholder="Type here........" />
        <button type="submit" class="btn btn-primary">Search</button>
    </form>
</div> -->
<!-- End Search Box -->