<!-- Start SaaS Main Banner -->
<!-- End SaaS Main Banner -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100 bg-gray">
    <div class="shape2"><img src="assets/img/shape2.png" style="width: 220px;" alt="img"></div>
    <div class="shape4 rotateme"><img src="assets/img/shape4.png" alt="img"></div>
    <div class="shape9"><img src="assets/img/elipse0.png" style="width: 300px;" alt="img"></div>
    <div class="container pt-4">
        <div class="section-title">
            <h2>Soluciones digitales</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricingTable first animationcard">
                    <div class="cardheader">
                        <img src="assets/img/card-g360.png" alt="">
                    </div>
                    <div class="pricing-content">
                        <div style="height: 40px;">
                            <h2>GESTIÓN 360</h2>
                        </div>
                        <div style="position: relative; height:160px">
                            <p>Plataforma modular para digitalizar <br>
                                la preventa, venta y posventa. <br>
                                Marca blanca <br>
                                WhatsApp como principal interfaz.</p>
                        </div>
                    </div>
                    <a routerLink="/" class="btn btn-primary">ME INTERESA</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricingTable second">
                    <div class="cardheader">
                        <img src="assets/img/card-muvinjakue.png" alt="">
                    </div>
                    <div class="pricing-content">
                        <div style="height: 40px;">
                            <h2>
                                <div style="padding: 0 40px; display: inline-block;">
                                    <img src="assets/img/jakue-logo.png" style="height: 25px; padding-right: 20px;" alt="">
                                    <img src="assets/img/muvin-logo.png" style="height: 30px;" alt="">
                                </div>
                            </h2>
                        </div>
                        <div style="position: relative; height:160px">
                            <p>Logistica agroindustrial Inteligente.<br>
                                Optimiza comunicación, trazabilidad<br>
                                y gestión de los procesos de carga,<br>
                                descarga y planificación de <br>
                                capacidades.</p>
                        </div>
                    </div>
                    <a routerLink="/" class="btn btn-primary">CONOCE MÁS</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricingTable third">
                    <div class="cardheader">
                        <img src="assets/img/card-amedida.png" alt="">
                    </div>
                    <div class="pricing-content">
                        <div style="height: 40px;">
                            <h2>SOFTWARE A MEDIDA</h2>
                        </div>
                        <div style="position: relative; height:160px">
                            <p>Soluciones digitales en función de tus <br class="nonebr">
                                necesidades. <br>
                                Entregables funcionales y escalables <br class="nonebr">
                                a corto plazo. Equipo 100% propio y <br class="nonebr">
                                amplio stack tecnológico</p>
                        </div>
                    </div>
                    <a routerLink="/" class="btn btn-primary">DESCUBRE MÁS</a>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Pricing Area -->

<!-- Start Features Area -->
<section class="features-area saas-features ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Somos una empresa de tecnología 
                con productos propios y 
                desarrollo de software a medida 
                para proyectos estratégicos.</h2>
            </div>

            <a routerLink="/" class="btn btn-primary">HABLEMOS</a>

        <div class="row specialrow">
            <div class="col-lg-4 col-md-6 mobilerow left">
                <div class="single-features">
                    <div class="icon">
                        <span class="material-symbols-outlined">
                            calendar_month
                        </span>
                    </div>
                    <h3>6</h3>
                    <p>Años de experiencia <br>
                        brindando soluciones digitales</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mobilerow right">
                <div class="single-features">
                    <div class="icon">
                        <span class="material-symbols-outlined">
                            schedule
                        </span>
                    </div>
                    <h3>+200.000</h3>
                    <p>Horas <br> de desarrollo</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mobilerow left">
                <div class="single-features">
                    <div class="icon">
                        <span class="material-symbols-outlined">
                            headphones
                        </span>
                    </div>
                    <h3>+60</h3>
                    <p>Colaboradores <br> en Latinoamérica</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mobilerow right">
                <div class="single-features">
                    <div class="icon">
                        <span class="material-symbols-outlined">
                            chat
                        </span>
                    </div>
                    <h3>+107.512</h3>
                    <p>Conversaciones <br>
                        en nuestros Chatbots <br>
                        (enero a mayo 2022)</p>
                    </div>
            </div>
            
            <div class="col-lg-4 col-md-6 mobilerow left">
                <div class="single-features large">
                    <div class="icon">
                        <span class="material-symbols-outlined">
                            work_history
                        </span>
                    </div>
                    <h3>+30</h3>
                    <p>Proyectos desarrollados <br>
                        en las industrias de Agro, <br>
                        Logística, E-commerce y Seguros</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mobilerow right">
                <div class="single-features large">
                    <div class="icon">
                        <span class="material-symbols-outlined">
                            groups
                        </span>
                    </div>
                    <h3>+30.000</h3>
                    <p>Usuarios conectados <br>
                        a través de nuestras <br>
                        soluciones.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/elipsemedio.png" style="width: 111px;" alt="img"></div>
</section>
<!-- End Features Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="shape3"><img src="assets/img/elipsemedio.png" style="width: 110px;" alt="img"></div>
    <div class="container">
        <div class="section-title">
            <h2>Clientes</h2>
        </div>

        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-feedback">
                <img src="assets/img/logos-01.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-02.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_03.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_04.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_05.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_06.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_07.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_08.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_09.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_10.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_11.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_12.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_13.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_14.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_15.png" alt="">
            </div>
            <div class="single-feedback">
                <img src="assets/img/logos-web-aisa-2022_16.png" alt="">
            </div>

            
        </div>
    </div>

</section>
<!-- End Feedback Area -->

<!-- Start Work Process Area -->
<section class="saas-work-process ptb-100">

    <div class="container">
        <div>
            <img class="imgfondo imagebounce" src="assets/img/mindset.png" alt="">
        </div>
        <div class="section-title">
            <h2>Nuestro Mindset</h2>
        </div>

        <div class="row prow">
            <div class="col-lg-3 col-md-6">
                <div class="single-work-process multiplataforma">
                    <div class="icon">
                        <span class="material-symbols-outlined">
                            devices
                        </span>
                    </div>
                    <h3>MULTIPLATAFORMA</h3>
                    <p>Web, aplicaciones, bot y
                        WhatsApp como interfaz que
                        pueden complementarse
                        entre sí.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process usabilidad">
                    <div class="icon">
                        <span class="material-symbols-outlined">
                            favorite
                        </span>
                    </div>
                    <h3>USABILIDAD</h3>
                    <p>Experiencia de usuario óptima,
                        teniendo en cuenta diseño de
                        interfaces y el canal con mayor
                        predisposición a operar.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process eco">
                    <div class="icon">
                        <span class="material-symbols-outlined">
                            autorenew
                        </span>
                    </div>
                    <h3>ECOSISTEMA 360</h3>
                    <p>Todos los actores incluídos.
                        Integración con sistemas del
                        negocios y otras soluciones
                        del mercado.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process largesingle esca">
                    <div class="icon">
                        <span class="material-symbols-outlined">
                            signal_cellular_alt
                        </span>
                    </div>
                    <h3>ESCALABILIDAD</h3>
                    <p>Pensamos el desarrollo
                        del producto de
                        forma robusta y sólida, pero
                        empezamos con un Mínimo
                        Producto Viable que permita
                        una rápida implementación y
                        feedback de los usuarios, con
                        el menor costo inicial.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work Process Area -->

<!-- Start Get Started Area -->
<section class="get-started ptb-100">
    <div class="shape1"><img src="assets/img/Elipse329.png" style="width: 220px;" alt="img"></div>
    <div class="shape6"><img src="assets/img/isotipomarcaagua.png" alt="img"></div>
    <div class="container">
        <div class="section-title">
            <h2>Potencia tu negocio <span>ahora</span></h2>
            <div class="bar"></div>
            <p>Nos comunicaremos para conocer tu necesidad <br class="nonebr2">
                y encontrar la solución más adecuada.</p>
        </div>

        <div class="get-started-form" *ngIf="!send">
            <form (ngSubmit)="save()" [formGroup]="formContacto">
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="mail" placeholder="Mail o número telefónico (celular)">
                </div>
                
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="name" placeholder="Nombre y Apellido">
                </div>

                <div class="form-group">
                    <input type="text" class="form-control" formControlName="web" placeholder="Web">
                </div>

                <button type="submit" class="btn btn-primary">Enviar</button>
            </form>
        </div>
        <div *ngIf="send" class="mensaje">
            <div class="icon">
                <span class="material-symbols-outlined">
                    check_circle
                </span>
            </div>
            <h2>Tu mensaje fue enviado. <br> 
            Te contactaremos a la brevedad.</h2>
        </div>
    </div>

    <!-- <canvas id="canvas"></canvas> -->
</section>
<!-- End Get Started Area -->


<!-- Start Overview Area -->
<section class="overview-section ptb-100 bg-gray">
    <div class="shape5"><img src="assets/img/elipse0.png" style="width: 220px;" alt="img"></div>
    <div class="container">

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">

                <div class="col-lg-4 col-md-4 overview-content">
                    <h3 class="titleweb">Talentos</h3>            
                    <p>Queremos evolucionar personas 
                        para transformar Latinoamérica
                        desde el desarrollo digital, 
                        con foco en la agroindustria.</p>
                    <span>Sumate al equipo <br>
                        de Alternativas Inteligentes</span>
                        <div class="bar"></div>
                    <a routerLink="/" class="btn btn-primary">Ver oportunidades laborales</a>
                </div>

                <div class="col-lg-8 col-md-8 overview-img">
                    <h3 class="titlemob">Talentos</h3>            
                    <img class="imagebounce" src="assets/img/talentos.png" alt="image">
                </div>

            </div>
        </div>
    </div>

</section>
<!-- End Overview Area -->

<div class="wpp">
    <a href="http://wa.me" target="_blank" rel="noopener noreferrer"><img src="assets/img/iconowpp.png" alt=""></a>
</div>

