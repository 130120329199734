import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-saas-landing',
    templateUrl: './saas-landing.component.html',
    styleUrls: ['./saas-landing.component.scss']
})
export class SaasLandingComponent implements OnInit {

    constructor( private titleService: Title,
                private fb: FormBuilder,
                 ) {
    }

    send = false;
    formContacto: FormGroup;

    ngOnInit() {
        this.formContacto = this.fb.group({
            mail: ['', [Validators.required]],
            name: ['', [Validators.required]],
            web: ['', [Validators.required]],
          });
    }

    save(){
        this.send = true;
        console.log(this.formContacto);
        setTimeout(() => {
            this.send = false;
        }, 10000);
    }

}