import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SaasLandingComponent } from './components/pages/saas-landing/saas-landing.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { FunfactComponent } from './components/common/funfact/funfact.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    SaasLandingComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    FunfactComponent,
    PartnerComponent,
    // AppLandingComponent,
    // DigitalMarketingComponent,
    // ProductLandingComponent,
    // SoftwareLandingComponent,
    // BookLandingComponent,
    // StartupAgencyComponent,
    // PaymentProcessingComponent,
    // AboutUsComponent,
    // FeaturesComponent,
    // ServicesComponent,
    // GalleryComponent,
    // BlogComponent,
    // BlogDetailsComponent,
    // ContactUsComponent,
    // NotFoundComponent,
    // FaqComponent,
    // ComingSoonComponent,
    // LoginComponent,
    // SignupComponent,
    // TeamComponent,
    // PricingComponent,
    // ProjectsComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
