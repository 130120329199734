<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">
    <div class="shape8"><img src="assets/img/elipse0.png" style="width: 220px;" alt="img"></div>
    <div class="container">
        <div class="row rowrespfoot">
            <div class="col-lg-3 col-md-6 center">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logowhite.svg" alt="logo2"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pt-4">

                    <ul class="list">
                        <li>Buenos Aires y Rosario, Argentina</li>
                        <li>Santa Cruz, Bolivia</li>
                        <li>Lima, Peru</li>
                        <li>Montevideo, Uruguay</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                
            </div>

            <div class="col-lg-3 col-md-6 lastfoot">
                <div class="single-footer-widget align-items">
                    <h3>Conecta</h3>
                    <div>
                        <a href="https://www.facebook.com/somosaisa" target="_blank" rel="noopener noreferrer"><img src="assets/img/RS-01-face.svg" alt=""></a>
                         <a href="https://www.facebook.com/somosaisa" target="_blank" rel="noopener noreferrer"><img src="assets/img/RS-02-ig.svg" class="pl-2" alt=""></a>
                         <a href="https://www.facebook.com/somosaisa" target="_blank" rel="noopener noreferrer"><img src="assets/img/RS-03-tw.svg" class="pl-2" alt=""></a>
                         <a href="https://www.facebook.com/somosaisa" target="_blank" rel="noopener noreferrer"><img src="assets/img/RS-04-in.svg" class="pl-2" alt=""></a>
                         <a href="https://www.facebook.com/somosaisa" target="_blank" rel="noopener noreferrer"><img src="assets/img/RS-05-yt.svg" class="pl-2" alt=""></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="copyright-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>&copy; 2020 Kilian. All rights reserved</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<!-- <div class="back-to-top">Top</div> -->
<!-- End Back Top top -->